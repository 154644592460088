<template>
  <div></div>
</template>

<script>
import router from "@/router";

export default {
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const orgCode = this.$route.params.org_code

      let params = new URL(location.href).searchParams;

      if (params.get('liff.state')) {
        router.push('/' + orgCode + '/liff/proj/abbott-and' + params.get('liff.state'));
      }
    }
  }
}
</script>
